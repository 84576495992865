import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/games",
          name: "games",
          component: () => import("@/view/pages/Games.vue")
        },
        {
          path: "/contact",
          name: "contact",
          component: () => import("@/view/pages/Contact.vue")
        },
        {
          path: "/support-center",
          name: "support",
          component: () => import("@/view/pages/Support.vue")
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/Dashboard.vue"),
      children: [
        {
          name: "home",
          path: "/",
          component: () => import("@/view/pages/Dashboard.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-6.vue")
    }
  ]
});
